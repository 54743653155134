
















































import { Component, Vue } from 'vue-property-decorator';
import VButton from '@/components/VButton.vue';
import { Action } from 'vuex-class';
import * as types from '@/store/main/types';

@Component({
  components: { VButton },
})
export default class KennisBank extends Vue {

  @Action('getKennisBank', { namespace: 'main' }) private getKennisBankAction!: types.GetKennisBankAction;
  private entries: Array<{ header: string, text: string, category: string }> = [];
  private loadingKennisBankItems = false;

  private searchText = '';

  private get trimmedSearchText() {
    return this.searchText.trim().toLowerCase();
  }

  private get searchedEntries() {
    return this.entries.filter((entry) => {
      return (entry.text.toLowerCase().includes(this.trimmedSearchText)) ||
      (entry.header.toLowerCase().includes(this.trimmedSearchText)) ||
      (entry.category.toLowerCase().includes(this.trimmedSearchText));
    });
  }

  private created() {
    this.loadingKennisBankItems = true;
    this.getKennisBankAction().then((response) => {
      this.entries = response.map((kennisBankItem) => {
        const result = {
          header: kennisBankItem.question,
          text: kennisBankItem.answer,
          category: kennisBankItem.subject,
        };
        if (kennisBankItem.source !== '') {
          (result as any).source = kennisBankItem.source;
        }
        return result;
      });
    }).finally(() => {
      this.loadingKennisBankItems = false;
    });
  }
}
